import React from 'react'
import PropTypes from 'prop-types'

// Generic
import Container from '../../shared/Container/Container'
import SearchForm from '../SearchForm/SearchForm'

// Utils
import { urlMap } from '../../../utils/urls'
import Button from '../Button/Button'
import classNames from 'classnames'

const BlogArticleIndexHero = (props) => {
  const { handleSubmit, handleSearchInputChange, inputElement, title, body, formIsLoading, location } = props
  const isForBusiness = location?.pathname?.includes('for-businesses') || false;
  return (
    <section className='pt-32 pb-20 sm:pt-48 sm:pb-32 bg-offwhite'>
      <Container className='flex flex-col lg:flex-row justify-between items-start lg:items-center'>
        <div>
          <h1 className='mb-4'>{title}</h1>
          <p className='text-2xl mb-8'>{body}</p>
          <SearchForm
            action={`${urlMap.blog_article}search`}
            handleSubmit={handleSubmit}
            handleSearchInputChange={handleSearchInputChange}
            inputElement={inputElement}
            inputName='q'
            isLoading={formIsLoading}
          />
        </div>

        <ul className='flex pt-12 lg:pt-0'>
              <li>
                <Button
                  url={`${urlMap.blog_article}#content`}
                  linkType='Document'
                  element={!isForBusiness ? 'span' : 'a'}
                  color={!isForBusiness ? 'primary' : 'secondary'}
                  className={classNames({
                    'pointer-events-none': !isForBusiness,
                    'border-primary': !isForBusiness
                  })}
                >
                  Graduates
                </Button>
              </li>
              <li>
                <Button
                  url={`${urlMap.blog_article}category/for-businesses#content`}
                  linkType='Document'
                  element={isForBusiness ? 'span' : 'a'}
                  color={isForBusiness ? 'primary' : 'secondary'}
                  className={classNames({
                    'pointer-events-none': isForBusiness,
                    'border-primary': isForBusiness
                  })}
                >
                  Businesses
                </Button>
              </li>
            </ul>
      </Container>
    </section>
  )
}

BlogArticleIndexHero.propTypes = {
  handleSubmit: PropTypes.func,
  handleSearchInputChange: PropTypes.func,
  inputElement: PropTypes.object,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  formIsLoading: PropTypes.bool
}

export default BlogArticleIndexHero
